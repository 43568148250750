import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import fc_logo from "../../images/fc_logo.png";
import "./Landing.css";

function Landing() {
  const quotes = [
    "Simple Solutions",
    "Credited Answers",
    "Researched Conclusions",
    "Human Driven Products",
    "Life Easier",
    "Work Faster",
    "Projects Finish",
    "Start-ups Start",
    "Small Companies Big",
    "College Students Learn",
    "Meaningful Progress",
    "Change for the Better",
    "Software... for people who want to help people",
  ];

  return (
    <Container fluid id="Landing">
      <Row
        className="justify-content-center align-items-center"
        style={{ height: "inherit" }}
      >
        <Col className="text-center" id="brandDiv" xs="12" md="6">
          <img src={fc_logo} alt="" width="200" />
          <h1>Fells Code</h1>
        </Col>
        <Col xs="12" md="6">
          <p className="text-center">
            We make <TextSwitcher texts={quotes} />
          </p>
        </Col>
      </Row>
    </Container>
  );
}

const TextSwitcher = ({ texts }: { texts: string[] }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (index < texts.length - 1) {
        setIndex(index + 1);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [index, texts.length]);

  return <h4>{texts[index]}</h4>;
};

export default Landing;
