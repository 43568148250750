import { Col, Container, Row } from "react-bootstrap";

import pencil from "../../images/pencils.png";
import develop from "../../images/laptop.png";
import cloud from "../../images/deploy.png";
import Banner from "../Banner/Banner";

import "./Services.css";

function Services() {
  return (
    <Container fluid>
      <Row id="Services" className="align-items-center">
        <Col xs="12">
          <Row className="justify-content-center">
            <Col xs="10">
              <h1 className="text-center pt-4">
                <span className="yellowText">Fells Code</span> Core Values
                <hr />
              </h1>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="4" className="text-center">
              <img src={pencil} alt="" width="200" />
              <h3 className="blueText">People First</h3>
              <p>
                Every solution we make is for people or to benefit people. We
                are not in the business of solutions to replace people. We are
                in the business of making solutions that empower individuals,
                teams, and company to use their people optimally!
              </p>
            </Col>
            <Col xs="12" md="4" className="text-center">
              <img src={develop} alt="" width="200" />
              <h3 className="blueText">Find Answers</h3>
              <p>
                Fells Code is focused on solving real world problems with
                elegant and affordable solutions. We want to make software
                solutions that people can take and run with it.
              </p>
            </Col>
            <Col xs="12" md="4" className="text-center">
              <img src={cloud} alt="" width="200" />
              <h3 className="blueText">D.I.Y.</h3>
              <p>
                Deploy it yourself! We want to make as many solutions as we can
                deployable by those who chose to use our products. Why? Cause we
                want more competitors in the space. We want more individuals to
                build businesses and we want more people to care about people.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Banner type="red" />
    </Container>
  );
}

export default Services;
