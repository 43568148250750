import { Col, Container, Row } from "react-bootstrap";

import "./About.css";
import Banner from "../Banner/Banner";

function About() {
  return (
    <Container fluid>
      <Row id="About" className="align-items-center justify-content-center">
        <Col xs="11" sm="10" md="8">
          <h1>
            The history of <span className="greenText">Fells Code</span>
            <hr />
          </h1>
          <p>Fells Code has been in business since 2015.</p>

          <p>
            We seek to be a prominent player in the software services industry,
            specializing in enterprise software solutions and services. With a
            passion for innovation and a commitment to excellence, we are
            dedicated to providing cutting-edge technology solutions that
            empower businesses to thrive in the digital age.
          </p>
        </Col>
      </Row>
      <Banner type="orange" />
    </Container>
  );
}

export default About;
