import landingLogo from "../../images/fc_logo.png";

function Logo() {
  return (
    <>
      <img src={landingLogo} alt="Fells Code logo" width="50%" />
    </>
  );
}

export default Logo;
