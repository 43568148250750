import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import fc_logo from "../../images/fc_logo.png";

import "./Footer.css";

function Footer() {
  return (
    <Container fluid>
      <Row id="Footer" className="justify-content-center py-5">
        <Col xs="12" md="10" id="site-map" className="text-start">
          <Row>
            <Col className="pt-3">
              <img src={fc_logo} width={60} height={50} />
            </Col>
          </Row>
          <Row className="p-3">
            <Col>
              <h6>
                <b>Fells Code</b>
              </h6>
              <ul>
                <li>About Fells Code</li>
                <li>Blog</li>
              </ul>
            </Col>
            <Col>
              <h6>
                <b>Products</b>
              </h6>
              <ul>
                <li>Drop Auth UI - (Comming Soon)</li>
                <li>Drop Auth API - (Comming Soon)</li>
                <li>DART - (Comming Soon)</li>
                <li>Intent Software Platform - (Comming Soon)</li>
              </ul>
            </Col>
            <Col>
              <h6>
                <b>Resources</b>
              </h6>
              <ul>
                <li>Product Documentation - (Comming Soon)</li>
                <li>Developer Documentation - (Comming Soon)</li>
                <li>Contact us</li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row id="copy-right" className="justify-content-center py-3">
        <Col>
          <p className="text-start">Copyright &copy; 2024 Fells Code</p>
        </Col>
        <Col>
          <p className="text-end me-2">
            <Link className="me-4" to="/privacy">
              Privacy Information
            </Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
