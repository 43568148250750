function PageNotFound() {
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Where ya going, Cowboy!?</p>
    </div>
  );
}

export default PageNotFound;
