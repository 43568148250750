import { Col, Container, Row } from "react-bootstrap";

import "./Socials.css";

function Socials() {
  return (
    <Container fluid>
      <Row id="Socials" className="justify-content-center align-items-center">
        <Col xs="12" md="8">
          <h1>
            Are accolades are growing!
            <br />
            Check back as we release our products to the market
          </h1>
        </Col>
      </Row>
    </Container>
  );
}

export default Socials;
