import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import Privacy from "./components/Privacy/Privacy";
import Logo from "./components/Logo/Logo";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <PageNotFound />,
  },
  {
    path: "privacy",
    element: <Privacy />,
  },
  {
    path: "logo",
    element: <Logo />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
